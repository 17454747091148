const SetNetlifyABTestGroup = async (groupFloatVal) => {
  document.cookie = 'nf_ab=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  const newCookieParts = []
  document.cookie.split('; ').forEach((c) => {
    if (c.indexOf('nf_ab=' !== -1)) {
      const a = new Date()
      a.setTime(a.getTime() + 365 * 24 * 60 * 60 * 1000)
      const expires = `expires=${a.toUTCString()}`
      const d = `nf_ab=${groupFloatVal}; expires=${expires}; path=/;`
      newCookieParts.push(d)
    }
  })
  document.cookie = newCookieParts.join('; ')
}

export default SetNetlifyABTestGroup
