import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import LoadingAnimation from 'src/components/LoadingAnimation'

import SetNetlifyABTestGroup from 'src/utils/SetNetlifyABTestGroup'

const CONTROL_FLOAT_VAL = 0.8

class ResetTestGroup extends React.Component {
  componentDidMount() {
    const { redirectTo } = this.props
    SetNetlifyABTestGroup(CONTROL_FLOAT_VAL).then(() => navigate(redirectTo))
  }

  render() {
    return <LoadingAnimation />
  }
}

ResetTestGroup.propTypes = {
  redirectTo: PropTypes.string.isRequired,
}

export default ResetTestGroup
